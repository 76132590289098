import * as THREE from 'three';
import 'bootstrap/js/src/collapse.js';
import screenfull from 'screenfull';
// import { Player } from 'vimeo-threejs-player';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import Player from '@vimeo/player';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { SvgParticles } from './SvgParticles';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';

import TextLoader from './TextLoader';
import Screen from './Screen';
import { insertAt } from './util';
import helvetiker from '../fonts/abcfont.json';
import spinner from '../icons/spinner.svg';
import imgSimone from '../images/simone_dede_ayivi_mayra_wallraff.jpg';
import twitter from '../images/twitter.svg';
import insta from '../images/instagram.svg';

import bg2 from '../videos/bg2full.mp4';
import bgAudio from '../videos/intro.mp3';

import m1 from '../videos/aufbau-1.mp4';
import m2 from '../videos/aufbau-2.mp4';
import m3 from '../videos/aufbau-3.mp4';
import m4 from '../videos/aufbau-4.mp4';
import m5 from '../videos/aufbau-5.mp4';
import m6 from '../videos/aufbau-6.mp4';
import m7 from '../videos/aufbau-7.mp4';
import m8 from '../videos/aufbau-8.mp4';
import m9 from '../videos/aufbau-9.mp4';

import ms1 from '../videos/StillsStuhl/AufbauStuhlV1.jpg';
import ms2 from '../videos/StillsStuhl/AufbauStuhlV2.jpg';
import ms3 from '../videos/StillsStuhl/AufbauStuhlV3.jpg';
import ms4 from '../videos/StillsStuhl/AufbauStuhlV4.jpg';
import ms5 from '../videos/StillsStuhl/AufbauStuhlV5.jpg';
import ms6 from '../videos/StillsStuhl/AufbauStuhlV6.jpg';
import ms7 from '../videos/StillsStuhl/AufbauStuhlV7.jpg';
import ms8 from '../videos/StillsStuhl/AufbauStuhlV8.jpg';
import ms9 from '../videos/StillsStuhl/AufbauStuhlV9.jpg';

import v11 from '../videos/1-1.mp4';
import v12 from '../videos/1-2.mp4';
import v13 from '../videos/1-3.mp4';
import v14 from '../videos/1-4.mp4';
import v15 from '../videos/1-5.mp4';
import v16 from '../videos/1-6.mp4';

import v21 from '../videos/2-1.mp4';
import v22 from '../videos/2-2.mp4';
import v23 from '../videos/2-3.mp4';
import v24 from '../videos/2-4.mp4';
import v25 from '../videos/2-5.mp4';
import v26 from '../videos/2-6.mp4';

import v31 from '../videos/3-1.mp4';
import v32 from '../videos/3-2.mp4';
import v33 from '../videos/3-3.mp4';
import v34 from '../videos/3-4.mp4';
import v35 from '../videos/3-5.mp4';
import v36 from '../videos/3-6.mp4';

import v41 from '../videos/4-1.mp4';
import v42 from '../videos/4-2.mp4';
import v43 from '../videos/4-3.mp4';
import v44 from '../videos/4-4.mp4';
import v45 from '../videos/4-5.mp4';
import v46 from '../videos/4-6.mp4';

import v51 from '../videos/5-1.mp4';
import v52 from '../videos/5-2.mp4';
import v53 from '../videos/5-3.mp4';
import v54 from '../videos/5-4.mp4';
import v55 from '../videos/5-5.mp4';
import v56 from '../videos/5-6.mp4';

import v61 from '../videos/6-1.mp4';
import v62 from '../videos/6-2.mp4';
import v63 from '../videos/6-3.mp4';
import v64 from '../videos/6-4.mp4';
import v65 from '../videos/6-5.mp4';
import v66 from '../videos/6-6.mp4';

import v71 from '../videos/7-1.mp4';
import v72 from '../videos/7-2.mp4';
import v73 from '../videos/7-3.mp4';
import v74 from '../videos/7-4.mp4';
import v75 from '../videos/7-5.mp4';
import v76 from '../videos/7-6.mp4';

import v81 from '../videos/8-1.mp4';
import v82 from '../videos/8-2.mp4';
import v83 from '../videos/8-3.mp4';
import v84 from '../videos/8-4.mp4';
import v85 from '../videos/8-5.mp4';
import v86 from '../videos/8-6.mp4';

import v91 from '../videos/9-1.mp4';
import v92 from '../videos/9-2.mp4';
import v93 from '../videos/9-3.mp4';
import v94 from '../videos/9-4.mp4';
import v95 from '../videos/9-5.mp4';
import v96 from '../videos/9-6.mp4';

import ee11 from '../videos/ee-forest-1.mp4';
import ee12 from '../videos/ee-forest-2.mp4';
import ee13 from '../videos/ee-forest-3.mp4';
import ee14 from '../videos/ee-forest-4.mp4';
import ee15 from '../videos/ee-forest-5.mp4';
import ee16 from '../videos/ee-forest-6.mp4';

import ee21 from '../videos/ee-playground-1.mp4';
import ee22 from '../videos/ee-playground-2.mp4';
import ee23 from '../videos/ee-playground-3.mp4';
import ee24 from '../videos/ee-playground-4.mp4';
import ee25 from '../videos/ee-playground-5.mp4';
import ee26 from '../videos/ee-playground-6.mp4';

import ee31 from '../videos/ee-schrebergarten-1.mp4';
import ee32 from '../videos/ee-schrebergarten-2.mp4';
import ee33 from '../videos/ee-schrebergarten-3.mp4';
import ee34 from '../videos/ee-schrebergarten-4.mp4';
import ee35 from '../videos/ee-schrebergarten-5.mp4';
import ee36 from '../videos/ee-schrebergarten-6.mp4';

import ee41 from '../videos/ee-innsbrucker-1.mp4';
import ee42 from '../videos/ee-innsbrucker-2.mp4';
import ee43 from '../videos/ee-innsbrucker-3.mp4';
import ee44 from '../videos/ee-innsbrucker-4.mp4';
import ee45 from '../videos/ee-innsbrucker-5.mp4';
import ee46 from '../videos/ee-innsbrucker-6.mp4';

import sub1de from '../subtitles/1_DE.vtt';
import sub1en from '../subtitles/1_EN.vtt';
import sub2de from '../subtitles/2_DE.vtt';
import sub2en from '../subtitles/2_EN.vtt';
import sub3de from '../subtitles/3_DE.vtt';
import sub3en from '../subtitles/3_EN.vtt';
import sub4de from '../subtitles/4_DE.vtt';
import sub4en from '../subtitles/4_EN.vtt';
import sub5de from '../subtitles/5_DE.vtt';
import sub5en from '../subtitles/5_EN.vtt';
import sub6de from '../subtitles/6_DE.vtt';
import sub6en from '../subtitles/6_EN.vtt';
import sub7de from '../subtitles/7_DE.vtt';
import sub7en from '../subtitles/7_EN.vtt';
import sub8de from '../subtitles/8_DE.vtt';
import sub8en from '../subtitles/8_EN.vtt';
import sub9de from '../subtitles/9_DE.vtt';
import sub9en from '../subtitles/9_EN.vtt';

// import teddy from '../icons/teddy.png';
import pingpong from '../icons/pingpong.png';
import balloon from '../icons/balloon.png';
import bear from '../icons/bear.png';
import frisbee from '../icons/frisbee.png';

import GUI from 'lil-gui';
import { TextureLoader } from 'three';

export default class Sketch {
  constructor( options ) {
    this.time = 0;
    this.container = options.dom;

    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;

    this.goToMenu = this.goToMenu.bind( this );
    this.updateRotation = this.updateRotation.bind( this );

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    this.videos = [];
    this.videoTextures = [];
    this.screenObjects = [];
    this.screens = new THREE.Group();
    this.videoObjects = new THREE.Group();
    this.easterMeshes = [];
    this.eSprites = {};

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( 0x000000 );

    this.camera = new THREE.PerspectiveCamera( 52, this.width / this.height, 0.1, 4000 );
    this.camera.position.set( 0, 0, 450 );
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();

    let pixelRatio = window.devicePixelRatio;
    let AA = true;
    if (pixelRatio > 2) {
      AA = false;
    }

    this.renderer = new THREE.WebGLRenderer( {
      antialias: AA,
      autoClear: true,
      powerPreference: "high-performance",
    } );

    this.params = {
      autoRotateSpeed: 0.7,
      rotateKey: null,
      screenRotateSpeed: 0.0,
      isScreensPlaying: false,
      currentVideo: 0,
      currentVideoTime: 0,
      bgVideo: 1,
      bgVideoLoaded: false,
      menuVideosLoaded: false,
      appState: 'landing', //loading, canStart, menu, chapter, easteregg, canPlay6
      bgColor: '#5d446a',
      isRotating: true,
      shouldPlayIntroVideo: true,
      activeSubtitleLanguage: -1,
      subMenuOpen: false,
    };

    this.currentVideoSet = 'a1';
    this.alreadyViewed = [];
    this.intersects = null;

    this.menuSrcs = [ m1, m2, m3, m4, m5, m6, m7, m8, m9 ];
    this.menuStills = [ ms1, ms2, ms3, ms4, ms5, ms6, ms7, ms8, ms9 ];

    this.videoSrcs = [
      [ null, v11, v12, null, v13, v14, null, v15, v16 ],
      [ null, v21, v22, null, v23, v24, null, v25, v26 ],
      [ null, v31, v32, null, v33, v34, null, v35, v36 ],
      [ null, v41, v42, null, v43, v44, null, v45, v46 ],
      [ null, v51, v52, null, v53, v54, null, v55, v56 ],
      [ null, v61, v62, null, v63, v64, null, v65, v66 ],
      [ null, v71, v72, null, v73, v74, null, v75, v76 ],
      [ null, v81, v82, null, v83, v84, null, v85, v86 ],
      [ null, v91, v92, null, v93, v94, null, v95, v96 ],
    ];

    this.subTitleSrcs = [
      [ sub1de, sub1en ],
      [ sub2de, sub2en ],
      [ sub3de, sub3en ],
      [ sub4de, sub4en ],
      [ sub5de, sub5en ],
      [ sub6de, sub6en ],
      [ sub7de, sub7en ],
      [ sub8de, sub8en ],
      [ sub9de, sub9en ],
    ];

    this.easterEggSrcs = [
      [ null, ee11, ee12, null, ee13, ee14, null, ee15, ee16 ],
      [ null, ee21, ee22, null, ee23, ee24, null, ee25, ee26 ],
      [ null, ee31, ee32, null, ee33, ee34, null, ee35, ee36 ],
      [ null, ee41, ee42, null, ee43, ee44, null, ee45, ee46 ],
    ];

    this.chapterTitles = [
      'Unsere\nAussprache',
      'Bloß nicht\nauffallen',
      'Cousins fünften\nGrades',
      'Unsere Mütter\nUnsere Väter',
      'Wir sind in dieses\nLand gekommen',
      'Wie wir\nmitfeiern',
      'Wo wir\nherkommen',
      'Niemand beleidigt\nunsere Kinder',
      'Wir stehen auf\nden Schultern',
    ];

    this.subtitleDiv = document.querySelector('.subtitles');
    this.introOverlay = document.querySelector('.intro-1');
    this.bgVideo = document.getElementById( 'bgvideo' );
    this.bgAudio = document.getElementById( 'bgaudio' );
    this.menuButton = document.querySelector( '.menu-icon' );
    this.fullButton = document.querySelector( '.full-icon' );
    this.playPauseButton = document.querySelector( '.play-pause-button' );
    this.timeDisplay = document.querySelector( '.current-time' );
    this.chapterControls = document.querySelector( '.chapter-controls' );
    this.backButton = document.querySelector( '.back-icon' );
    this.resetButton = document.querySelector( '.reset-icon' );
    this.subOff = document.querySelector( '.sub-off' );
    this.subDe = document.querySelector( '.sub-de' );
    this.subEn = document.querySelector( '.sub-en' );
    this.skipLoadingDiv = document.querySelector( '.skip-loading' );
    this.skipLoadingButton = document.querySelector( '.btn-skip-intro' );

    this.renderer.setSize( this.width, this.height );
    this.container.appendChild( this.renderer.domElement );

    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.2;
    this.controls.enablePan = false;
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI;
    this.controls.autoRotate = true;
    this.controls.autoRotateSpeed = this.params.autoRotateSpeed;
    this.controls.rotateSpeed = 0.4;
    this.controls.minDistance = 100;
    this.controls.maxDistance = 3000;
    this.controls.zoomSpeed = 0.3;

    this.isMenuOpen = false;
    this.menu = document.querySelector( 'menu' );

    this.ninePositions = this.getScreenPositions( 9, 700 );

    //screen 0,3,6 will disaapear
    this.sixPositions = this.getScreenPositions( 6, 690 );
    insertAt(this.sixPositions,0,this.ninePositions[0]);
    insertAt(this.sixPositions,3,this.ninePositions[3]);
    insertAt(this.sixPositions,6,this.ninePositions[6]);

    this.canPlayThroughs = 0;

    this.controls.update();

    this.setupListeners();
    this.addBackgroundVideo();
    this.addEasterEggs();
    this.addScreens();
    this.render();
    this.resize();

    console.log( 'Website created by https://eedee.net' );
  }

  loadIntro() {
    const that = this;
    this.textLoader = new TextLoader( [], {
      delay: 2800,
      source: document.querySelector( '.dialog' ),
      parent: document.querySelector( '.intro-2' ),
      animateOpacityOn: document.querySelector( '.intro' ),
      onComplete: () => {
        if ( that.params.appState === 'landing' || this.params.appState === 'canStart' ) {
          that.setState( 'intro2' );
        }
      }
    } );
  }

  goToInstructions() {
    const oldContainer = document.querySelector( '.text-loader-bg .container' );
    oldContainer.remove();
    const bg = document.querySelector( '.text-loader-bg' );
    let instructions = document.querySelector( '.dialog2' );

    bg.style.display = 'block';
    bg.style.overflow = 'scroll';

    instructions = bg.appendChild( instructions );
  }

  getScreenPositions( numberOfScreens = this.number, radius = this.radius ) {
    const ret = [];
    for ( let i = 0; i < numberOfScreens; i++ ) {
      const alpha = ( i / numberOfScreens ) * ( Math.PI * 2 );
      const posX = Math.sin( alpha ) * radius;
      const posZ = Math.cos( alpha ) * radius;
      ret.push( new THREE.Vector3( posX, 0, posZ ) );
    }
    return ret;
  }

  onChangeVideo =
    value => {
      this.videos?.map( ( v, idx ) => {
        v.pause();
        const vSrc = this.videoSrcs[ value - 1 ][ idx ];
        if (vSrc !== null ){
          v.src = vSrc;
          v.load();
        }
      });
    };

  initTweens() {
    const that = this;
    this.to6Tweens = [];
    this.to9Tweens = [];

    for ( let i = 0; i<9; i++ ) {
      const t = new TWEEN.Tween( this.screens.children[i].position )
      .to( that.sixPositions[i], 2000 )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate( () => {
        that.screens.children[i].lookAt( 0,0,0 );
      })
      .onComplete( () => {
        that.isChapterOverview = false;
        that.isTweening = false;
        that.screenObjects[ i ].changeToVideos();
        that.backButton.style.display = 'block';
      });
      that.to6Tweens.push( t );

      //also tween opacity of screen
      if ( i % 3 === 0 ) {
        const t = new TWEEN.Tween( this.screens.children[i].children[0].material )
        .to( { opacity: 0 }, 1000 )
        .easing(TWEEN.Easing.Quadratic.Out);
        that.to6Tweens.push( t );
      }
    }

    for ( let i = 0; i<9; i++ ) {
      const t = new TWEEN.Tween(that.screens.children[i].position)
      .to( that.ninePositions[i], 2000 )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate( () => {
        that.screens.children[i].lookAt( 0,0,0 );
      })
      .onComplete( () => {
        that.isChapterOverview = true;
        that.isTweening = false;
      });

      that.to9Tweens.push( t );

      //also tween opacity of screen
      if ( i % 3 === 0 ) {
        const t = new TWEEN.Tween( this.screens.children[i].children[0].material )
        .to( { opacity: 1 }, 1000 )
        .easing(TWEEN.Easing.Quadratic.In);

        that.to9Tweens.push( t );
      }
    }
  }

  onSubtitleChange() {
    const subLanguages = [ 'de', 'en' ];
    for (var i = 0; i < this.videos[1].textTracks.length; i++) {
      // For the 'subtitles-off' button, the first condition will never match so all will subtitles be turned off
      if (this.videos[1].textTracks[i].language == subLanguages[ this.params.activeSubtitleLanguage ]) {
         this.videos[1].textTracks[i].mode = 'showing';
         console.log( 'onsubtitleChange', this.videos[1].textTracks );
         if ( this.videos[1].textTracks[ this.params.activeSubtitleLanguage ]
          && this.videos[1].textTracks[ this.params.activeSubtitleLanguage ].activeCues
          && this.videos[1].textTracks[ this.params.activeSubtitleLanguage ].activeCues.length ) {
            this.setSubtitle(this.videos[1].textTracks[ this.params.activeSubtitleLanguage ].activeCues[0].text);
         }
      }
      else {
         this.videos[1].textTracks[i].mode = 'hidden';
         this.setSubtitle('');
      }
    }
  }

  setSubtitle( text ) {
    if ( text && text !== '' ) {
      const subtitleWrap = document.createElement("div");
      subtitleWrap.classList.add( 'subtitle' );
      subtitleWrap.innerHTML = text;
      this.subtitleDiv.replaceChildren( subtitleWrap );
    } else {
      this.subtitleDiv.replaceChildren();
    }
  }

  onVideoChangeAddSubtitleTracks( chapterIdx ) {
    console.log( 'onVideoChangeAddSubtitleTracks', chapterIdx );

    const trackDE = document.createElement("track");
    trackDE.kind = "captions";
    trackDE.label = "Deutsche Untertitel";
    trackDE.srclang = "de";
    trackDE.src = this.subTitleSrcs[chapterIdx][0];
    trackDE.addEventListener("load", function() {
      console.log( 'loaded german subtitles' );
      //  this.mode = "showing";
      //  vid.textTracks[0].mode = "showing"; // thanks Firefox
    });
    trackDE.addEventListener( 'cuechange', ( e ) => {
      if ( this.params.activeSubtitleLanguage === 0 ) {
        if ( e.target && this.videos[1].textTracks[1].activeCues && this.videos[1].textTracks[0].activeCues[0] ) {
          console.log( this.videos[1].textTracks[0].activeCues[0].text );
          this.setSubtitle(this.videos[1].textTracks[0].activeCues[0].text);
        }
      }
    });
  
    const trackEN = document.createElement("track");
    trackEN.kind = "captions";
    trackEN.label = "English Subtitles";
    trackEN.srclang = "en";
    trackEN.src = this.subTitleSrcs[chapterIdx][1];
    trackEN.addEventListener("load", function() {
      console.log( 'loaded english subtitles' );
      //  this.mode = "showing";
      //  this.videos[1].textTracks[1].mode = "showing"; 
    });
    trackEN.addEventListener( 'cuechange', ( e ) => {
      if ( this.params.activeSubtitleLanguage === 1 ) {
        if ( e.target && this.videos[1].textTracks[1].activeCues && this.videos[1].textTracks[1].activeCues[0] ) {
          console.log( this.videos[1].textTracks[1].activeCues[0].text );
          this.setSubtitle(this.videos[1].textTracks[1].activeCues[0].text);
        }
      }
    });
  
    this.videos[1].replaceChildren( trackDE, trackEN );
    // this.videos[1].appendChild(trackDE);
    // this.videos[1].appendChild(trackEN);
  }

  setCurrentVideoTime( time ) {
    if ( this.params.currentVideoTime !== time && ! isNaN( time ) ) {
      this.params.currentVideoTime = time;
      const t = parseInt( time );
      const minus = t !== 0 ? '-' : '';
      this.timeDisplay.innerHTML = `${ minus }${ Math.floor( t % 3600 / 60 ) }:${ Math.floor(t % 60).toString().padStart(2,'0') } <span class="video-title">${ this.params.currentTitle }</span>`;
    }
  }

  waitAndPlay() {
    if ( this.params.appState === 'chapter' ) {
      this.timeDisplay.innerHTML = `${ spinner }`;
    }

    if (
      this.params.appState === 'loading' 
      && this.canPlayThroughs === 9
    ) {

      this.canPlayThroughs = 0;
      this.params.menuVideosLoaded = true;

      if ( this.params.bgVideoLoaded ) {
        this.setState( 'canStart' );
      }

    } else if (
      this.params.appState === 'chapter'
      && this.canPlayThroughs === 6
    ) {

      this.videos?.map( ( v ) => {
        v.play();
      } );
      this.params.isScreensPlaying = true;
      this.playPauseButton.classList.remove( 'disabled' );
      this.canPlayThroughs = 0;

    }
  }

  onBgVideoCanPlayThrough( e ) {
    if ( ! this.params.bgVideoLoaded ) {
      this.bgVideo.play();
      this.params.bgVideoLoaded = true;
      if ( this.params.menuVideosLoaded ) {
        this.setState( 'canStart' );
      }
      this.bgVideo.removeEventListener( 'canplaythrough', this.onBgVideoCanPlayThrough );
    }
  }

  hideEastereggs() {
    this.easterMeshes.forEach( ( mesh ) => {
      mesh.material.opacity = 0;
    });
  }

  showEastereggs() {
    this.easterMeshes.forEach( ( mesh ) => {
      mesh.material.opacity = 1;
    });
  }

  addBackgroundVideo() {
    this.sphereGeometry = new THREE.SphereGeometry( 870, 60, 40 );
    // invert the geometry on the x-axis so that all of the faces point inward
    this.sphereGeometry.scale( - 1, 1, 1 );

    this.bgVideo.addEventListener( 'canplaythrough', this.onBgVideoCanPlayThrough.bind( this ) );

    this.material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
    this.bgMesh = new THREE.Mesh( this.sphereGeometry, this.material );
    this.bgMesh.material.color.set( {r:255, g:255, b:255 } );
    this.scene.add( this.bgMesh );
  }

  goToMenu() {
    if ( this.videoTimeout ) {
      clearTimeout( this.videoTimeout );
    }
    this.params.isEasterEgg = false;
    this.canPlayThroughs = 0;
    this.controls.autoRotateSpeed = this.params.autoRotateSpeed;

    this.bgMesh.material.map = this.bgTexture;
    this.bgMesh.material.needsUpdate = true;
    this.bgVideo.play();

    this.showEastereggs();
    this.canPlayThroughs = 0;

    this.videos?.map( ( v, idx ) => {
      v.pause();
      v.currentTime = 0;
    });

    this.screenObjects.map( ( screen, idx ) => {
      screen.showText();
      const chapter = idx + 1;
      screen.changeToStills( this.alreadyViewed.includes( chapter ) );
    });

    this.to9Tweens.forEach( (tween) => {
      tween.start();
    });

    this.bgMesh.material.color.setHex(0xffffff);

    this.setState( 'menu' );
    this.chapterControls.classList.add( 'disabled' );
    this.timeDisplay.style.opacity = 0;

    this.container.style.cursor = 'auto';
    this.updateRotation();

    this.setSubtitle('');
  }

  /**
   * Change the textures of the intro videos if needed
   */
  maybeChangeIntroTextures() {
    if ( this.params.shouldPlayIntroVideo ) {
      this.params.shouldPlayIntroVideo = false;
    }
  }

  /**
   * Go to selected chapter
   *
   * 1: pause screen videos + load new
   * 2: start tweens
   * 3: update screen controls
   * @param {int} chapter number
   */
  goToChapter( number, isEasterEgg = false ) {
    
    this.hideEastereggs();
    this.maybeChangeIntroTextures();

    this.setState( 'chapter' );
    this.params.isEasterEgg = isEasterEgg;

    this.backButton.style.display = 'none';

    if ( ! isEasterEgg && ! this.alreadyViewed.includes( number ) ) {
      this.alreadyViewed.push( number );
      this.params.currentTitle = this.chapterTitles[ number - 1 ];
    }

    this.screenObjects.map( ( screen ) => {
      screen.showText();
      screen.material.color.setHex(0x000000);
      screen.material.map = null;
      screen.material.needsUpdate = true;
    });
    this.canPlayThroughs = 0;
    this.videos?.map( ( v, idx ) => {
      v.pause();
      let vSrc = '';
      if ( ! isEasterEgg ) {
        vSrc = this.videoSrcs[ number - 1 ][ idx ];
      } else {
        vSrc = this.easterEggSrcs[ number - 1 ][ idx ];
      }
      if (vSrc !== null ){
        v.src = vSrc;
        v.load();
      }
    });

    if ( isEasterEgg ) {
      this.params.currentTitle = '';
      this.bgVideo.pause();
      this.bgMesh.material.map = null;
      this.bgMesh.material.needsUpdate = true;
      this.resetCamera(2500);
    } else {
      this.onVideoChangeAddSubtitleTracks(  number - 1 );
      this.onSubtitleChange();
    }

    this.screenObjects.map( ( screen ) => {
      screen.hideText();
    });

    this.to6Tweens.forEach( (tween) => {
      tween.start();
    });

    this.bgMesh.material.color.setHex(0x753afb);
    this.bgAudio.pause();

    this.chapterControls.classList.remove( 'disabled' );
    this.timeDisplay.style.opacity = 1;
    this.timeDisplay.innerHTML = `${ spinner }`;
    this.canPlayThroughs = 0;
    this.updateRotation();
    // this.bgMesh.material.color.lerp( toColor, 0.5 );
  }

  /**
   * Starts the loading process, user needs to click in some browsers
   * to enable video loading.
   * 
   * 1: load bg video (large)
   * 2: load 9 menu screen videos 
   */
  start() {
    //1: load bg video
    this.bgVideo.src = bg2;
    this.bgVideo.load();
    this.menu.classList.add( 'menu-animated' );

    this.bgTexture = new THREE.VideoTexture( this.bgVideo );
    this.bgTexture.minFilter = THREE.LinearFilter;
    this.bgTexture.magFilter = THREE.LinearFilter;
    this.bgTexture.format = THREE.RGBAFormat;
    this.bgMesh.material.map = this.bgTexture;
    this.bgMesh.material.needsUpdate = true;

    this.bgAudio.volume = 0.5;
    this.bgAudio.src = bgAudio;
    this.bgAudio.load();

    this.videos?.map( ( v, idx ) => {
      v.pause();
      const vSrc = this.menuSrcs[ idx ];
      v.src = vSrc;
      v.load();
    });
  }

  resetCamera( time=2500 ) {
    new TWEEN.Tween( this.controls.object.position )
      .to( {x: 0, y:0, z:400 }, time )
      .easing(TWEEN.Easing.Quadratic.Out)
      .start();
    this.controls.object.updateProjectionMatrix();
  }

  setState( state ) {
    this.onStateChange( state );
    this.params.appState = state;
  }

  onStateChange( newState ) {
    //appState: 'landing', loading, canStart, menu, chapter, easteregg, canPlay6
    switch ( newState ) {
      case 'loading':
        this.loadIntro();
        this.start();
        // this.introOverlay.style.display = 'none';
        document.querySelector( '.intro-1' ).style.opacity = 0;
        document.querySelector( '.intro-1' ).style.pointerEvents = 'none';
        document.querySelector( '.intro-2' ).style.opacity = 1;
        break;
      case 'intro2':
        this.skipLoadingButton.innerHTML = 'weiter →';
        document.querySelector( '.intro-2' ).style.opacity = 0;
        document.querySelector( '.intro-3' ).style.opacity = 1;
        break;
      case 'intro3':
        document.querySelector( '.intro-3' ).style.opacity = 0;
        setTimeout( () => {
          document.querySelector( '.intro-3' ).style.display = 'none';
        }, 1000 );
        break;
      case 'canStart':
        this.skipLoadingDiv.style.display = 'block';
        break;
      case 'menu':

        //fake resize event
        if (typeof(Event) === 'function') {
          // modern browsers
          window.dispatchEvent(new Event('resize'));
        } else {
          // for IE and other old browsers
          // causes deprecation warning on modern browsers
          var evt = window.document.createEvent('UIEvents'); 
          evt.initUIEvent('resize', true, false, window, 0); 
          window.dispatchEvent(evt);
        }

        const intro = document.querySelector( '.intro' );
        this.textLoader.cancelAnimations();
        this.bgAudio.play();
        if ( intro ) {
          intro.style.display = 'none';
          this.skipLoadingDiv.style.display = 'none';
        }
        if ( this.params.shouldPlayIntroVideo ) {
          this.resetCamera( 0 );
          this.videos?.map( ( v ) => {
            v.play();
          } );
        }
        break;
      case 'chapter':
        break;
      case 'easterEgg':
        break;
      default:
        console.warn( 'unknown state' );
    }
  }

  setupListeners() {
    const that = this;


    let isSupported = true;
    var agent = navigator.userAgent.toLowerCase();
    if(agent.indexOf('firefox') >= 0){
      if(agent.indexOf("android") >= 0){
        isSupported = false;
      }
    }

    if ( ! isSupported ) {
      document.querySelector( '.not-supported' ).style.display = 'flex';
      this.menuButton.style.zIndex = 1000;
      this.menu.style.zIndex = 1000;
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        if ( ! screenfull.isFullscreen ) {
          document.querySelector( '.full-icon' ).classList.add( 'disabled' );
        } else {
          document.querySelector( '.full-icon' ).classList.remove( 'disabled' );
        }
      });
    }

    this.container.addEventListener( 'pointermove', this.onPointerMove.bind( this ), false );
    // document.addEventListener('touchend', ( event ) => {
    //   // event.preventDefault();
    //   that.mouse.x = (event.changedTouches[0].pageX / window.innerWidth) * 2 - 1;
    //   that.mouse.y = -(event.changedTouches[0].pageY / window.innerHeight) * 2 + 1;
    //   // console.log( 'touch', this.mouse.x, this.mouse.y );
    //   // raycaster.setFromCamera(mouse, camera);
    //   that.intersects = this.raycaster.intersectObjects( this.scene.children );

    //   that.handleInteraction();
    // }, false);
    
    window.addEventListener( 'resize', this.resize.bind( this ) );

    this.skipLoadingButton.addEventListener( 'click', ( e ) => {
      if ( that.params.appState === 'canStart' ) {
        that.setState( 'intro2' );
      } else if( that.params.appState === 'dialog1' ) {
        that.setState( 'intro3' );
      } else {
        that.setState( 'menu' );
      }
    });

    this.introOverlay.addEventListener( 'click', ( e ) => {
      that.setState( 'loading' );
    });

    document.addEventListener('keydown', ( e ) => {
      switch( e.key ) {
        case " ":
          that.params.isRotating = ! this.params.isRotating;
          that.updateRotation();
          break;

        case "ArrowLeft":
          that.params.screenRotateSpeed = -0.5;
          that.params.rotateKey = 'left';
          // that.params.isRotating = false;
          break;

        case "ArrowRight":
          that.params.screenRotateSpeed = 0.5;
          that.params.rotateKey = 'right';
          // that.params.isRotating = false;
          break;
      }
    });

    document.addEventListener('keyup', ( e ) => {
      if (e.isComposing || e.keyCode === 229) {
        return;
      }

      if ( e.key === "ArrowLeft" || e.key === "ArrowRight" ) {
        that.params.rotateKey = null;
      }
    });

    this.container.addEventListener( 'mousedown', () => {
      that.clickTime = new Date();
    });

    this.container.addEventListener( 'touchstart', () => {
      that.clickTime = new Date();
    });

    this.container.addEventListener( 'click', that.handleInteraction.bind( this ) );

    this.menuButton.addEventListener( 'click', this.toggleMenu.bind( this ));
    this.fullButton.addEventListener( 'click', () => {
    	if (screenfull.isEnabled) {
        if ( ! screenfull.isFullscreen ) {
          screenfull.request();
        } else {
          screenfull.exit();
        }
      } else {
        // Ignore or do something else
      }
    });
    this.playPauseButton.addEventListener( 'click', this.playPause.bind( this ) );
    this.backButton.addEventListener( 'click', this.goToMenu.bind( this ) );
    this.resetButton.addEventListener( 'click', () => {
      this.resetCamera( 2500 );
    });
    this.subDe.addEventListener( 'click', () => {
      this.params.activeSubtitleLanguage = 0;
      this.subDe.classList.add('active');
      this.subEn.classList.remove('active');
      this.onSubtitleChange();
      this.subEn.style.opacity = 0;
      this.subDe.style.opacity = 0;
      this.params.subMenuOpen = false;
      this.subOff.classList.add('active');
    });
    this.subEn.addEventListener( 'click', () => {
      this.params.activeSubtitleLanguage = 1;
      this.subEn.classList.add('active');
      this.subDe.classList.remove('active');
      this.onSubtitleChange();
      this.subEn.style.opacity = 0;
      this.subDe.style.opacity = 0;
      this.params.subMenuOpen = false;
      this.subOff.classList.add('active');
    });
    this.subOff.addEventListener( 'click', () => {
      if ( ! this.params.subMenuOpen ) {
        this.subEn.style.opacity = 1;
        this.subDe.style.opacity = 1;
        this.params.subMenuOpen = true;
      } else {
        this.params.activeSubtitleLanguage = -1;
        this.subEn.classList.remove('active');
        this.subDe.classList.remove('active');
        this.onSubtitleChange();
        this.subEn.style.opacity = 0;
        this.subDe.style.opacity = 0;
        this.params.subMenuOpen = false;
        this.subOff.classList.remove('active');
      }
    });
  }

  handleInteraction() {
    const that = this;
    // console.log( 'handleInteraction');
    if (new Date() - that.clickTime > 150) {
      // console.log( 'time' );
      return;
    }
    if ( that.params.appState !== 'menu' ) {
      return;
    }
    
    that.intersects = that.raycaster.intersectObjects( that.scene.children );

    for ( let i = 0; i < that.intersects.length; i ++ ) {
      if (that.intersects[ i ].object.name.length ) {
        var str =that.intersects[ i ].object.name;
        var re = /screen-(\d+)/i;
        var found = str.match(re);
        if ( found && found.length ) {
          const chapter = parseInt( found[1] );
          that.goToChapter( chapter + 1 );
        } else {
          found = str.match(/easteregg-(\d+)/i);
          if ( found && found.length ) {
            const easterEgg = parseInt( found[1] );
            that.goToChapter( easterEgg, true );

            if (that.particleSystem ) {
             that.particleSystem.setSprite(that.eSprites[ found[1] - 1 ] );
             that.particleSystem.explode(that.intersects[ i ].object.parent.position );
            }
          }
        }
      }
    }
  }

  playPause() {
    if ( this.params.isScreensPlaying ) {
      this.playPauseButton.classList.add( 'paused' );
      this.videos?.map( ( v ) => {
        v.pause();
      });
      this.params.isScreensPlaying = false;
    } else {
      this.playPauseButton.classList.remove( 'paused' );
      this.videos?.map( ( v ) => {
        v.play();
      });
      this.params.isScreensPlaying = true;
    }
  }

  toggleMenu() {
    if ( ! this.isMenuOpen ) {
      this.menu.style.transform = 'translate3d(0,0,0)';
      this.isMenuOpen = true;
    } else {
      this.menu.style.transform = 'translate3d(0,-100%,0)';
      this.isMenuOpen = false;
    }
  }

  onPointerMove( event ) {
    // this.mouse.x = ( event.clientX / this.width ) * 2 - 1;
    // this.mouse.y = - ( event.clientY / this.height ) * 2 + 1;

    this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    this.intersects = this.raycaster.intersectObjects( this.scene.children );

    if ( this.params.appState === 'chapter') {
      this.container.style.cursor = 'grab';
      return;
    }

    for ( let i = 0; i < this.intersects.length; i ++ ) {
      if ( this.intersects[ i ].object.name.length ) {
        var str = this.intersects[ i ].object.name;
        var re = /screen-(\d+)/i;
        var found = str.match(re);
        if ( found && found.length ) {
          const chapter = parseInt( found[1] );
          this.container.style.cursor = 'pointer';
        } else {
          found = str.match(/easteregg-(\d+)/i);
          if ( found && found.length ) {
            const egg = parseInt( found[1] );
            this.container.style.cursor = 'pointer';
          } 
        }
      } else {
        if ( typeof found === 'undefined' ) {
          this.container.style.cursor = 'grab';
        }
      }
    }
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize( this.width, this.height );
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  onVideoEnded() {
    if ( this.params.appState === 'chapter' ) {
      this.videoTimeout = setTimeout( this.goToMenu, 3000 );
    }
  }
  
  addScreens() {

    const that = this;

    this.screenWidth = 300;
    this.screenHeight = this.screenWidth * 16 / 9;

    const videoEls = document.querySelectorAll( '#v1, #v2, #v3, #v4, #v5, #v6, #v7, #v8, #v9' );
    [ ...videoEls ].map( (el) => {
      el.addEventListener( 'canplay', ( event ) => {
          that.canPlayThroughs += 1;
          that.waitAndPlay();
      });
      el.onerror = function() {
        console.log("Error " + videoElement.error.code + "; details: " + videoElement.error.message);
      };
      this.videos.push( el );
    });

    this.videos[1].ontimeupdate =
      evt => this.setCurrentVideoTime( this.videos[1].duration - evt.target.currentTime );
    this.videos[1].onseeking =
      evt => this.setCurrentVideoTime( this.videos[1].duration - evt.target.currentTime );
    this.videos[1].onPause =
      evt => this.setCurrentVideoTime( this.videos[1].duration - evt.target.currentTime );
    this.videos[1].addEventListener( 'ended', this.onVideoEnded.bind( this ) );

    this.fontLoader = new FontLoader();
    this.fontLoader.load( helvetiker, function ( font ) {
      for ( let i = 0; i<9; i++ ) {
        const vTex = new THREE.VideoTexture( that.videos[i] );
        vTex.minFilter = THREE.LinearFilter;
        vTex.magFilter = THREE.LinearFilter;
        vTex.format = THREE.RGBAFormat;
        const textPosition = [ 'topleft', 'topright' ][ i % 2 ];
        const theScreen = new Screen(
          that.ninePositions[ i ],
          that.screenWidth,
          that.screenHeight,
          i,
          vTex,
          font,
          `${ that.chapterTitles[ i ]}`,
          textPosition,
          that.menuStills[ i ],
        );
        that.screenObjects.push( theScreen );
        that.screens.add( theScreen.getObjects() );
      }
      that.screens.rotateY( Math.PI*1.4);
      that.scene.add( that.screens );
      that.initTweens();
    });
  }

  addEasterEggs() {
    const that = this;
    const loader = new THREE.TextureLoader();

    // load a SVG resource
    loader.load(
      // resource URL
      pingpong,
      // called when the resource is loaded
      function ( texture ) {
        const material = new THREE.MeshBasicMaterial( {
          map: texture,
          transparent: true,
          side: THREE.DoubleSide,
        } );

        const geometry = new THREE.PlaneGeometry(150,150);
        const particle = new THREE.Mesh( geometry, material );
        
        particle.name = 'easteregg-1';
        particle.position.set( 80,-550,150 );
        particle.lookAt( 0, 0, 0 );
        that.easterMeshes.push( particle );
        that.scene.add( particle );
        that.eSprites[ 0 ] = texture;
      },
      function ( error ) {
        console.log( 'An error happened', error );
      }
    );

    loader.load( balloon,
      function ( texture ) {
        const material = new THREE.MeshBasicMaterial( {
          map: texture,
          transparent: true,
          side: THREE.DoubleSide,
        } );

        const geometry = new THREE.PlaneGeometry(150,150);
        const particle = new THREE.Mesh( geometry, material );
        
        particle.name = 'easteregg-2';
        particle.position.set( 30,550,50 );
        particle.lookAt( 0, 0, 0 );

        that.easterMeshes.push( particle );
        that.scene.add( particle );
        that.eSprites[ 1 ] = texture;
      },
      function ( error ) {
        console.log( 'An error happened', error );
      }
    );

    loader.load( frisbee,
      function ( texture ) {
        const material = new THREE.MeshBasicMaterial( {
          map: texture,
          transparent: true,
          side: THREE.FrontSide,
          // color: 0xff00f2,
        } );

        const geometry = new THREE.PlaneGeometry(150,150);
        const particle = new THREE.Mesh( geometry, material );
        
        particle.name = 'easteregg-3';
        particle.position.set( 550,520,-70 );
        particle.lookAt( 0, 0, 0 );
        // particle.rotateZ(Math.PI);
        // particle.rotateX(Math.PI);

        that.easterMeshes.push( particle );
        that.scene.add( particle );
        that.eSprites[ 2 ] = texture;
      },
      function ( error ) {
        console.log( 'An error happened', error );
      }
    );

    loader.load( bear,
      function ( texture ) {
        const material = new THREE.MeshBasicMaterial( {
          map: texture,
          transparent: true,
          side: THREE.DoubleSide,
        } );

        const geometry = new THREE.PlaneGeometry(150,150);
        const particle = new THREE.Mesh( geometry, material );
        
        particle.name = 'easteregg-4';
        particle.position.set( -500,-600,-200 );
        particle.lookAt( 0, 0, 0 );

        that.easterMeshes.push( particle );
        that.scene.add( particle );
        that.eSprites[ 3 ] = texture;
      },
      function ( error ) {
        console.log( 'An error happened', error );
      }
    );

    const sprite = new THREE.TextureLoader().load( bear, ( tex ) => {
      that.particleSystem = new SvgParticles( tex, 400 );
      that.scene.add( this.particleSystem.particles );
    } );
  }

  updateRotation() {
    if ( ! this.params.isRotating ) {
      this.controls.autoRotateSpeed = 0;
    } else {
      if ( this.params.isEasterEgg ) {
        this.controls.autoRotateSpeed = this.params.autoRotateSpeed * 2.;
      } else {
        this.controls.autoRotateSpeed = this.params.autoRotateSpeed;
      }
    }
  }

  render() {
    this.time += 0.05;
    TWEEN.update();


    if ( this.params.rotateKey !== null ) {
      this.screens.rotation.y += this.params.screenRotateSpeed / 100.;
    }

    this.controls.update();

    // if ( this.bear ) {
    //   const rv = this.bear.position.clone().normalize();
    //   this.bear.rotateOnAxis( rv, Math.PI / 300. );
    // }

    	// update the picking ray with the camera and mouse position
    this.raycaster.setFromCamera( this.mouse, this.camera );

    this.particleSystem?.update();

    this.renderer.render( this.scene, this.camera );
    window.requestAnimationFrame( this.render.bind( this ) );
  }
}
import anime from 'animejs/lib/anime.es.js';

export default class TextLoader {
  constructor( htmlArray, options ) {
    this.settings = {
      delay: 3000, // the delay of loading each line
      parent: document.body,
      source: document.body,
      animateOpacityOn: null,
      onComplete: ( anim ) => {},
    };

    Object.assign( this.settings, options );

    this.lines = [ ...this.settings.source.children ];

    this.addDomElements();
    this.run();
    return this;
  }

  addDomElements() {
    this.bg = document.createElement("div");
    this.bg.classList.add( 'text-loader-bg' );

    this.container = document.createElement( 'div' );
    this.container.classList.add( 'container' );
    this.bg.append( this.container );

    this.overlay = document.createElement( 'div' );
    this.overlay.classList.add( 'overlay' );
    this.bg.append( this.overlay );

    this.settings.parent.appendChild( this.bg );
    this.lines.map( line => {
      // const lineEl = document.createElement( 'div' );
      // lineEl.innerHTML = line.innerHTML;
      line.classList.add( 'line' );
      this.container.appendChild( line );
    });
  }

  run() {
    this.container.translateY = '-100%';
    let totalTransform = 0;
    const transforms = [ ...document.querySelectorAll('.text-loader-bg .line') ].map( ( lineEl ) => {
      totalTransform -= lineEl.clientHeight;
      return totalTransform;
    });

    transforms[ transforms.length - 1 ] = transforms[ transforms.length - 2 ];
    // transforms.pop();
    // transforms.pop();

    this.basicTimeline = anime.timeline();

    this.basicTimeline.add({
      targets: '.text-loader-bg .line',
      easing: 'linear',
      keyframes: [
        {
          opacity: 1,
          duration: 200,
        },
        {
          opacity: 0.3,
          duration: this.settings.delay * 10,
        },
      ],
      delay: function(el, i) {
        return this.settings.delay * i;
      }.bind( this ),
    });

    anime.timeline().add({
      easing: 'easeOutQuart',
      targets: '.text-loader-bg .container',
      translateY: transforms,
      delay: this.settings.delay,
      duration: this.settings.delay * this.lines.length,
      complete: ( anim ) => {
        this.container.translateY = '-100%';
        this.settings.onComplete( anim );
      },
    });

    anime.timeline().add({
      easing: 'linear',
      targets: this.settings.animateOpacityOn,
      opacity: [ 1, 0.8 ],
      delay: this.settings.delay * 2,
      duration: this.settings.delay * ( this.lines.length - 2),
    });
  }

  getTargets(ani) {
    return ani.children.reduce(
      (all, one) => all.concat(getTargets(one)),
      ani.animatables.map((a) => a.target)
    );
  }

  cancel(ani) {
    this.getTargets(ani).forEach(anime.remove);
  }

  cancelAnimations() {
    [ ...anime.running ].forEach(anime.remove);
  }
}


import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

import textbgtex from '../images/gradient-1.png';
class Screen {
  constructor( position, width, height, index, texture, loadedFont, message, textposition = 'topleft', still ) {

    this.group = new THREE.Group();

    this.still = still;
    this.textureLoader = new THREE.TextureLoader();
    this.vidTexture = texture;

    // add screen
    
    this.geometry = new THREE.PlaneBufferGeometry( width, height );
    this.material = new THREE.MeshBasicMaterial( {
      color: 0xffffff,
      side: THREE.DoubleSide,
      map: this.vidTexture,
      transparent: true,
    } );

    this.screen = new THREE.Mesh( this.geometry, this.material );
    this.screen.lookAt( 0, 0, 0 );
    this.screen.name = `screen-${ index }`;
    this.group.add( this.screen );

    //add font
    this.textposition = textposition;
    const fontMaterial = new THREE.LineBasicMaterial( {
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
    });

    if ( this.textposition === 'topleft' ) {
      message += ' ↘';
    } else {
      message += ' ↗';
    }

    const shapes = loadedFont.generateShapes( message, 100 );
    const fontGeometry = new THREE.ShapeGeometry( shapes );
    const text = new THREE.Mesh( fontGeometry, fontMaterial );
    text.material.depthWrite = false;
    text.scale.x = 0.15;
    text.scale.y = 0.15;
    text.scale.z = 0.15;

    // text.position.set( position.x, position.y, position.z );
    if ( this.textposition === 'topleft' ) {
      text.translateY( height / 2 );
      text.lookAt( 0, height / 2, 0 );
      text.translateX( - width / 2 - 30 );
      text.translateY( 40 );
      
    } else {
      text.translateY( - height / 2 );
      text.lookAt( 0, - height / 2, 0 );
      text.translateX( - width / 2 - 30 );
      text.translateY( - 30 );
    }

    const textBgMesh = new THREE.Mesh(
      new THREE.PlaneBufferGeometry( 210, 100 ),
      new THREE.MeshStandardMaterial( {
        color: 0x000000,
        side: THREE.FrontSide,
        opacity: 0.0,
        transparent: true,
      })
    );
    textBgMesh.material.depthWrite = false;
    textBgMesh.needsUpdate = true;

    const text2 = text.clone();

    text2.material = new THREE.LineBasicMaterial( {
      color: 0x000000,
      side: THREE.DoubleSide,
      transparent: true,
    });
    text2.material.depthWrite = false;
    text2.translateX( 0 );
    text2.translateY( 0 );
    text2.translateZ( -5 );
    // text2.material.color = 0x000000;
    text2.material.needsUpdate = true;

    textBgMesh.position.x = text.position.x;
    textBgMesh.position.y = text.position.y;
    textBgMesh.position.z = text.position.z;
    textBgMesh.translateX( 80 );
    textBgMesh.translateY( -10 );
    textBgMesh.translateZ( -5 );
    textBgMesh.name = `screen-${ index }`;

    this.group.add( textBgMesh );
    this.group.add( text );
    this.group.add( text2 );
    this.group.position.set( position.x, position.y, position.z );
    this.group.lookAt( 0, 0, 0 );
    this.group.name = `screen-${ index }`;
    return this;
  }

  getObjects() {
    return this.group;
  }

  setTexture( tex ) {
    this.screen.material.map = tex;
    this.screen.material.needsUpdate = true;
  }

  hideText() {
    new TWEEN.Tween( this.group.children[2].material )
      .to( { opacity: 0 }, 2000 )
      .start();
    new TWEEN.Tween( this.group.children[1].material )
      .to( { opacity: 0 }, 2000 )
      .start();
    new TWEEN.Tween( this.group.children[3].material )
      .to( { opacity: 0 }, 2000 )
      .start();
  }

  showText() {
    new TWEEN.Tween( this.group.children[2].material )
      .to( { opacity: 1 }, 2000 )
      .start();
    new TWEEN.Tween( this.group.children[3].material )
      .to( { opacity: 1 }, 2000 )
      .start();
  }

  changeToStills( alreadyViewed = false ) {
    this.textureLoader.load( this.still, ( tex ) => {
      if ( alreadyViewed ) {
        this.material.color.setHex(0x753afb);
      }
      this.material.map = tex;
    });
  }

  changeToVideos() {
    this.material.color.setHex(0xffffff);
    this.material.map = this.vidTexture;
  }
}

export default Screen; 
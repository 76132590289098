import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';

class SvgParticles {
  constructor( sprites, num, startPos = new THREE.Vector3(0, 0, 0 ) ) {
    this.sprite = sprites[0];
    this.num = num;
    this.maxInitRange = 200;
    this.speed = 3;
    this.isExploding = false;

    this.vertices = new Float32Array( this.num * 3 );
    this.speeds = new Float32Array( this.num * 3 );

    this.material = new THREE.PointsMaterial( {
      size: 160,
      map: this.sprite,
      side: THREE.FrontSide,
      depthTest: false,
      transparent: true,
    } );

    for ( let i = 0; i < this.num * 3; i += 3 ) {

      const x = Math.random() * 50 - 25;
      const y = Math.random() * 50 - 25;
      const z = Math.random() * 50 - 25;

      this.vertices[i] = x;
      this.vertices[i + 1] = y;
      this.vertices[i + 2] = z;
    }

    for ( let i = 0; i < this.num * 3; i += 3 ) {

      const x = THREE.MathUtils.randFloat( -this.speed, this.speed );
      const y = THREE.MathUtils.randFloat( -this.speed, this.speed );
      const z = THREE.MathUtils.randFloat( -this.speed, this.speed );

      this.speeds[i] = x;
      this.speeds[i + 1] = y;
      this.speeds[i + 2] = z;
    }

    this.geometry = new THREE.BufferGeometry();
    this.geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( this.vertices, 3 ) );

    this.particles = new THREE.Points( this.geometry, this.material );
    this.particles.visible = false;
    // this.init();

    return this;
  }

  setSprite( sprite ) {
    this.sprite = sprite;
    this.material.map = this.sprite;
    this.material.needsUpdate = true;
  }

  init() {
    const that = this;

    for ( let i = 0; i < this.num; i ++ ) {

      const particle = new THREE.Points( this.geometry, this.material );
      // console.log( 'particle', particle );
      // particle.rotation.x = Math.random() * 6;
      // particle.rotation.y = Math.random() * 6;
      // particle.rotation.z = Math.random() * 6;
      // particle.visible = false;
      this.particles.add( particle );
    }
  }

  reset() {
    for ( let i = 0; i < this.num * 3; i += 3 ) {

      const x = Math.random() * 50 - 25;
      const y = Math.random() * 50 - 25;
      const z = Math.random() * 50 - 25;

      this.vertices[i] = x;
      this.vertices[i + 1] = y;
      this.vertices[i + 2] = z;
    }
  }

  update() {
    if ( this.isExploding ) {
      for ( let i = 0; i < this.num * 3; i += 3 ) {
        this.geometry.attributes.position.array[i] += this.speeds[ i ];
        this.geometry.attributes.position.array[i+1] += this.speeds[ i + 1 ];
        this.geometry.attributes.position.array[i+2] += this.speeds[ i + 2 ];

        if (
          this.geometry.attributes.position.array[i] > 1800 ||
          this.geometry.attributes.position.array[i+1] > 1800 ||
          this.geometry.attributes.position.array[i+2] > 1800 )
        {
          this.particles.visible = false;
        }
      }
      this.geometry.attributes.position.needsUpdate = true;
    }
      // this.geometry.computeBoundingBox();
      // this.geometry.computeBoundingSphere();
    // }
  }

  explode( start = this.startPos ) {

    for ( let i = 0; i < this.num * 3; i += 3 ) {
      this.geometry.attributes.position.array[i] = start.x;
      this.geometry.attributes.position.array[i+1] = start.y;
      this.geometry.attributes.position.array[i+2] = start.z;
    }
    this.isExploding = true;
    this.particles.visible = true;

    // this.vertices = [];
    // for ( let i = 0; i < this.num; i ++ ) {

    //   const x = Math.random() * 2 - 1;
    //   const y = Math.random() * 2 - 1;
    //   const z = Math.random() * 2 - 1;

    //   this.vertices.push( x, y, z );
    // }
  }
}

export { SvgParticles };